import React, { useState, useEffect } from "react";
// import { Stage, Layer, Line, Image, Circle } from "react-konva";
import { View } from "react-native";
import { Glyphicon, PageHeader, Button, OverlayTrigger, Tooltip, Collapse, Well } from "react-bootstrap";
import { getCap, storeCap, getUserInformation, getUser, storeLatestUrls, getLatestUrls } from "../utils/Common";
import { loadCap, recordUserAction, getLatestStoredImages, getMonitors, getZones, patchZone } from "../utils/bedrockAPI";
import ZoneEditor from "../components/ZoneEditor";
import LoaderButton from "../components/LoaderButton";
// import Slider from "rc-slider";
// import 'rc-slider/assets/index.css';

var zoneEditorType="low";

export default function Zones(props) {
    const [buttonSize, setButtonSize] = useState("large");
    const [cap, setCap] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [imageUrls, setImageUrls] = useState(null);
    const [monitors, setMonitors] = useState(null);
    const [zones, setZones] = useState(null);
    const [newZones, setNewZones] = useState(null);
    // const [sliderValues, setSliderValues] = useState(null);
    const [w, setW] = useState(528);
    const [h, setH] = useState(360);
    // const [savedMessageTimer, setSavedMessageTimer] = useState(false);
    // const [savedMessageSeconds, setSavedMessageSeconds] = useState(0);
    const [zonesHelp,setZonesHelp] = useState(false);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
            setW(297);
            setH(203)
        }
    },[]);

    // useEffect(() => {
    //     let interval = null;
    //     console.log(savedMessageSeconds);
    //     console.log(savedMessageTimer);
    //     if(savedMessageTimer) {
    //         console.log("this")
    //         interval = setInterval(() => {
    //             setSavedMessageSeconds(savedMessageSeconds => savedMessageSeconds+1);
    //         },1000);
    //     }
    //     if(savedMessageSeconds > 5) {
    //         console.log("here")
    //         clearInterval(interval);
    //         props.setShowError(false);
    //         setSavedMessageTimer(false);
    //     }
    //     return () => clearInterval(interval);
    // },[savedMessageTimer,savedMessageSeconds])

    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);                    
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"zoneEditor",props.match.params.capid,{'userData':getUserInformation()});
    },[props.match.params.capid])

    useEffect(() => {
        async function accessMonitors() {
            const tempCams = await getMonitors(cap.capId);
            setMonitors(tempCams);
        }
        async function accessLastSavedImages() {
            try {
                var tempImageUrls = getLatestUrls();
                if(tempImageUrls==null) {
                    tempImageUrls = await getLatestStoredImages(cap.capId);
                    storeLatestUrls(tempImageUrls);
                }
                setImageUrls(tempImageUrls);
            } catch (e) {
                console.log(e);
            }
        }
        if(cap!=null) {
            setIsLoading(true);
            accessMonitors();
            accessLastSavedImages();
            if(cap.architecture.includes("JETSON")) {
                zoneEditorType="medium";
            } else {
                zoneEditorType="low";
            }
            setIsLoading(false);
        }
    },[cap])

    useEffect(() => {
        async function accessZones() {
            const tempZones = await getZones(cap.capId);
            var betterZones = {}
            var betterNewZones = {}
            // var tempSliders = {}
            for(var i=0; i<tempZones.length; i++) {
                betterZones[tempZones[i].monitorId] = tempZones[i];
                betterZones[tempZones[i].monitorId].changed = false;
                betterNewZones[tempZones[i].monitorId] = tempZones[i].zone.Coords;
                // tempSliders[tempZones[i].monitorId] = 0;
            }
            // setSliderValues(tempSliders);
            // console.log(betterZones);
            setZones(betterZones);
            setNewZones(betterNewZones);
        }
        if(monitors!=null) {
            accessZones();
        }
    }, [monitors])

    // useEffect(() => {
    //     if(zones) {
    //         console.log(zones);
    //     }
    // },[zones])

    function renderZoneEditor(zId,iId,t) {
        const updateZones = (monitorId,nodes) => {
            setNewZones({...newZones,[monitorId]:nodes});
        }

        function setSlider(v,id) {
            var newZone = zones[id];
            newZone.sensitivity = v;
            newZone.changed = true;
            setZones({...zones,[id]:newZone});
        }
        if(imageUrls[iId] && zones[zId]) {
            return (
                <View style={{flex:1, flexDirection: "column", minWidth:w, alignItems:"center"}}>
                    <ZoneEditor
                        zone={zones[zId].zone.Coords}
                        url={imageUrls[iId].url}
                        id={zId}
                        w={w}
                        h={h}
                        type={t}
                        onChange={updateZones}
                    />
                    {/* <View style={{flex:1, flexDirection:"row", width:w, alignItems: "flex-start"}}>
                        <View style={{flex:1, paddingRight:"10px", width:"30px"}}>
                            <h4>
                                {zones[zId].sensitivity}
                            </h4>
                        </View>
                        <View style={{width:w-30, paddingTop: "15px"}}>
                            <Slider
                                min={0} 
                                max={100}
                                value={zones[zId].sensitivity} 
                                onChange={(v) => setSlider(v,zId)}
                            />
                        </View>
                    </View> */}
                </View>
            )
        } else {
            return null;    
        }
    }

    function renderZones() {
        const saveZones = async () => {
            setIsLoading(true);
            for(const monitorId in newZones) {
                // if(zones[monitorId] && zones[monitorId].changed) {
                if(zones[monitorId]) {
                    var newNewZone = zones[monitorId].zone;
                    newNewZone.Coords = newZones[monitorId];
                    var sensitivity = zones[monitorId].sensitivity;
                    if(sensitivity < 70) sensitivity = 75;
                    const newZone = {userId:getUser().userId,zone:newNewZone,sensitivity:sensitivity}
                    const r = await patchZone(zones[monitorId].zoneId,newZone);
                    if(r.status===204) {
                        props.setErrorMessage("Zones Saved!");
                        props.setErrorStatus("success-message");
                        props.setShowError(true);
                    }
                    var changedZone = zones[monitorId];
                    changedZone.changed = false;
                    setZones({...zones,[monitorId]:changedZone});
                }
            }
            setIsLoading(false);
        }

        const tooltip=(
            <Tooltip id="save" style={{fontSize: "18px"}} disabled={buttonSize==="small"}>
                Save your Zones
            </Tooltip>
        )

        return (
            <View>
                <View style={{flex:1, flexDirection: "row", justifyContent: "space-between"}}>
                    <Button
                        bsStyle="primary"
                        bsSize={buttonSize}
                        onClick={() => {props.setShowError(false); props.history.push(`/caps/${cap.capId}`)}}
                    >
                        Back to CAP
                    </Button>
                    <View style={{flex:1, flexDirection:"row", justifyContent:"flex-end"}}>
                        <OverlayTrigger placement="left" overlay={tooltip}>
                            <LoaderButton 
                                bsStyle="success"
                                bsSize={buttonSize}
                                onClick={saveZones}
                                isLoading={isLoading}
                            >
                                <Glyphicon glyph="floppy-disk" />&nbsp;&nbsp;Save Zones
                            </LoaderButton>
                        </OverlayTrigger>
                    </View>
                </View>
                {cap && (
                    <PageHeader style={{marginTop:"-10px"}}>
                        <View style={{flex:1,flexDirection:"row",justifyContent:"space-between"}}>
                            <div>
			                    {cap.name}<small> {cap.neighborhood && "("+cap.neighborhood+")"}</small>
                            </div>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end"}}>
                                <Button
                                    bsStyle="default"
                                    bsSize={buttonSize}
                                    onClick={() => setZonesHelp(!zonesHelp)}
                                >
                                    <Glyphicon glyph="info-sign" />&nbsp;&nbsp;Help
                                </Button>
                            </View>
                        </View>
                        <View style={{paddingTop:"5px"}}>
                            <Collapse in={zonesHelp}>
                                <Well style={{fontSize:"16px"}}>
                                    Zones represent where the AI looks for detections in the views.<br /><br />
                                    We recommend keeping your zone tight to the area you care about<br /><br />
                                    Click and drag the nodes to adjust the zone.  Click the open circles to create new nodes.  Double click closed circles to delete nodes.<br /><br />
                                    Make sure you save your zones when you're done editing them.<br /><br />
                                    Zones can't be edited if the cameras are off.<br />
                                </Well>
                            </Collapse>
                        </View>
                    </PageHeader>
                )}
                {monitors && imageUrls && zones && (
                    <View style={{flex:1, flexDirection:"row", justifyContent:"center", flexWrap:"wrap"}}>
                        {renderZoneEditor(monitors.house[zoneEditorType].monitorId,monitors.house.medium.monitorId,zoneEditorType)}
                        {renderZoneEditor(monitors.neighbor[zoneEditorType].monitorId,monitors.neighbor.medium.monitorId,zoneEditorType)}
                        {renderZoneEditor(monitors.street[zoneEditorType].monitorId,monitors.street.medium.monitorId,zoneEditorType)}
                        {renderZoneEditor(monitors.driveway[zoneEditorType].monitorId,monitors.driveway.medium.monitorId,zoneEditorType)}
                    </View>
                )}
            </View>
        );
    }

    return (
        <div className="Zones">
            {renderZones()}
        </div>
    )
}
