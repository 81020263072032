import React, {useState, useEffect, useRef} from "react";
import { getCameraToken, setCameraToken, setCameras, getCameras, getMonitorsGroupById, getCap, storeCap, getLatestUrls, storeLatestUrls, getUserInformation, getUser } from "../utils/Common";
import { 
    Button, 
    Glyphicon, 
    Tooltip, 
    OverlayTrigger, 
    ButtonGroup, 
    Label,
    Well,
    Collapse
} from "react-bootstrap";
import { View } from "react-native";
import "./Camera.css";
// import { getMonitorStatus } from "../utils/cameraAPI";
import { loadCap, getLatestStoredImages, getMonitors, getImagesFromCapThroughCloud, recordUserAction, loginToCamerasThroughAPI, getMonitorAlarmStatus, postStreamControl } from "../utils/bedrockAPI";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import LoaderButton from "../components/LoaderButton";
import ReactPlayer from 'react-player';

const UPDATE_INTERVAL = 2; // Everytime the background snapshot is loaded
// const TIMEOUT = 120; // timeout so data doesn't balloon
const TIMEOUT = 45;
const URL_BASE = "https://bedrock:bedrock@data.bedrockwireless.com:"

export default function Camera3(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [cap, setCap] = useState(null);
    const [renderError, setRenderError] = useState(false);
    const [localCameras, setLocalCameras] = useState([]);
    const [bigCamera, setBigCamera] = useState(null);
    const [mediumCamera, setMediumCamera] = useState(null);
    const [url, setUrl] = useState("");
    const [urlHigh, setUrlHigh] = useState("");
    const [savedUrl, setSavedUrl] = useState("");
    // const [latestStreamImage, setLatestStreamImage] = useState("");
    const [time, setTime] = useState(0);
    const [streaming, setStreaming] = useState(false);
    const [fullyLoaded, setFullyLoaded] = useState(false);
    const [streamQuality, setStreamQuality] = useState("low");
    const [signalWeak, setSignalWeak] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [playPauseLoading, setPlayPauseLoading] = useState(false);
    const [changeStreamLoading, setChangeStreamLoading] = useState(false);
    const [showResolutionHelp, setShowResolutionHelp] = useState(false);
    const [imgDate, setImgDate] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [view, setView] = useState(1);
    const transformer = useRef();
    const componentMounted = useRef(true);
    const playerRef = useRef();
    const playerRefHigh = useRef();

    // 1 - Start clocks
    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
        const interval = setInterval(() => {
            setTime(time => time+1);
        },1000);

        return () => {
            clearInterval(interval);
            componentMounted.current = false;
        };
    },[]);

    // Don't let the user keep pulling live images while AFK
    useEffect(() => {
        if(streaming) {
            if(time > 0 && time%TIMEOUT===0) {
                // setTime(0);
                playPause(false);
            } else if(mediumCamera && time > 0 && time%UPDATE_INTERVAL===0) {
                // fetchAlarmStatus();
            }
        }
    },[time]);

    useEffect(() => {
        setView(props.match.params.view);
    },[props.match.params.view]);

    // 2 - Load the cap from memory 1st then api
    useEffect(() => {
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                if(tempCap == null || tempCap.capId !== props.match.params.capid) {
                    tempCap = await loadCap(props.match.params.capid);
                    storeCap(tempCap);                    
                }
                setCap(tempCap);
                setIsLoading(false);
            } catch(e) {
                console.log(e);
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"camera",props.match.params.capid,{'monitorId':props.match.params.monitorId,'userData':getUserInformation()});
    },[props.match.params.capid]);

    // 3 - Load camera metadata and saved urls from cloud images
    useEffect(() => {
        let isMounted = true;

        async function loadBigAndSmallCameras() {
            try {
                const cams = getCameras();
                if(cams==null || cams.capId !== cap.capId) {
                    const tempCams = await getMonitors(cap.capId);
                    if(isMounted) {
                        setLocalCameras(tempCams);
                        setCameras(tempCams);
                    }
                } else {
                    if(isMounted) {
                        setLocalCameras(cams);
                    }
                }
            } catch(e) {
                console.log(e);
            }
        }

        async function requestSavedCamera() {
            try {
                var tempImageUrls = getLatestUrls();
                if(tempImageUrls == null) {
                    tempImageUrls = await getLatestStoredImages(props.match.params.capid);
                    if(isMounted) {
                        storeLatestUrls(tempImageUrls);
                    }
                }
                if(isMounted) {
                    setSavedUrl(tempImageUrls[props.match.params.monitorid]);
                }
            } catch (e) {
                if(isMounted) {
                    setRenderError(true);
                }
                console.log(e);
            }
        }

        if(cap!=null) {
            if(isMounted) {
                setIsLoading(true);
                requestSavedCamera();
                loadBigAndSmallCameras();
                if(isMounted)
                    setIsLoading(false);
            }
        }
        return () => { isMounted = false; };
    },[cap]);

    // 4a - Set the different resolution monitors
    useEffect(() => {
        // monitorid in url is the high res stream (medium), get the grouping and assign the ultra res stream
        if(cap && localCameras) {
            const camerasAtDirection = getMonitorsGroupById(localCameras,props.match.params.monitorid);
            setBigCamera(camerasAtDirection.high);
            setMediumCamera(camerasAtDirection.medium);
        }
    },[localCameras]);

    // 4c - Load the high stream
    useEffect(() => {
        if(cap && savedUrl && bigCamera && mediumCamera) {
            if(!fullyLoaded) {
                setStreaming(true);
                setUrl(`${URL_BASE}${cap.port-3000}/camera/${view}/low/index.m3u8`);
                setUrlHigh(`${URL_BASE}${cap.port-3000}/camera/${view}/high/index.m3u8`);
                // setFullyLoaded(true);
                setPlaying(true);
                if(playerRef.current) {
                    const hls = playerRef.current.getInternalPlayer('hls');
                    if(hls) {
                        hls.on(hls.Events.ERROR, function(event,data) {
                            console.log(event,data);
                        });
                    }
                }
                if(playerRefHigh.current) {
                    const hls = playerRefHigh.current.getInternalPlayer('hls');
                    if(hls) {
                        hls.on(hls.Events.ERROR, function(event,data) {
                            console.log(event,data);
                        });
                    }
                }
            }
        }
    },[cap,savedUrl, bigCamera, mediumCamera]);

    // Fixes URLs when stream quality changes
    useEffect(() => {
        if(streamQuality && cap) {
            if(streamQuality==="low") {
                setUrl(`${URL_BASE}${cap.port-3000}/camera/${view}/low/index.m3u8`);
                setUrlHigh("");
            } else if(streamQuality==="high") {
                setUrlHigh(`${URL_BASE}${cap.port-3000}/camera/${view}/high/index.m3u8`)
                setUrl("");
            }
        }
    },[streamQuality]);

    async function playPause(play=true) {
        setPlayPauseLoading(true);
        if(streaming) {
            setImgDate(new Date());
            if(playerRef.current) {
                const hls = playerRef.current.getInternalPlayer('hls');
                if(hls) {
                    if(playing && !play) {
                        hls.stopLoad();
                        setPlaying(false);
                    } else if (!playing && play) {
                        hls.startLoad();
                        setPlaying(true);
                    }
                }
            }
        }
        // } else {
        //     setTime(0);
        // }
        setPlayPauseLoading(false);
    }

    // Useful for download button
    async function loadSnapshot() {
        setDownloadLoading(true);
        let tempSnapshot;
        if(bigCamera && streamQuality==="high") {
            try {
                tempSnapshot = await getImagesFromCapThroughCloud(cap,[bigCamera],null);
            } catch(e) {
                console.log("Not able to pull snapshot (high)",e);
                cameralogin(true);
            }
            if(tempSnapshot && bigCamera.monitorId in tempSnapshot) {
                if(componentMounted.current) {
                    // setLatestStreamImage(tempSnapshot[bigCamera.monitorId].url);
                    setDownloadLoading(false);
                    window.location.href = tempSnapshot[bigCamera.monitorId].url;
                }
            }
        } else if(mediumCamera && streamQuality==="low") {
            try {
                tempSnapshot = await getImagesFromCapThroughCloud(cap,[mediumCamera],null);
            } catch(e) {
                console.log("Not able to pull snapshot (med)", e);
                cameralogin(true);
            }
            if(tempSnapshot && mediumCamera.monitorId in tempSnapshot) {
                if(componentMounted.current) {
                    // setLatestStreamImage(tempSnapshot[mediumCamera.monitorId].url);
                    setDownloadLoading(false);
                    window.location.href = tempSnapshot[mediumCamera.monitorId].url;
                }
            }
        }
    }

    function renderLargeCamera() {
        function backToCaps() { 
            props.history.push(`/caps/${props.match.params.capid}`)
        }

        const tooltip=(
            <Tooltip id="refresh" style={{fontSize: "18px"}} disabled={buttonSize==="small"}>
                Refresh if stream is not loading
            </Tooltip>
        );

        return (
            <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <View ref={transformer}>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingLeft: "5px", paddingBottom: "5px"}}>
                        <View style={{flex:1,flexDirection:"column",maxWidth:buttonSize==="small"?"100px":"200px"}}>
                            <Button
                                bsStyle="primary"
                                bsSize={buttonSize}
                                disabled={isLoading}
                                onClick={!isLoading ? backToCaps : null}
                            >
                                {buttonSize==="small"?"Back":"Back to All Views"}
                            </Button>
                            <View style={{paddingTop:"5px"}}>
                                {!playing && fullyLoaded ? (
                                    <LoaderButton
                                        bsStyle={"warning"}
                                        bsSize={buttonSize}
                                        isLoading={playPauseLoading}
                                        onClick={() => window.location.reload(false)}
                                    >Refresh</LoaderButton>
                                ) : (
                                    <Label 
                                        style={buttonSize==="small"?{lineHeight:2,fontSize:"12px"}:{lineHeight:2,fontSize:"18px"}} 
                                        bsStyle={streaming&&fullyLoaded&&playing?"success":"warning"}
                                    >
                                        {streaming&&fullyLoaded&&playing?"Live":(streaming&&playing)?("Loading"+(time%3===0?".":time%3===1?"..":"...")):"Refresh"}
                                    </Label>
                                )}
                            </View>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', paddingRight:"5px", paddingLeft:"20px", flexWrap: 'wrap'}}>
                            <View style={{flex:1, maxWidth:"400px", flexDirection:"column"}}>
                                <View style={{flex:1,flexDirection:"row"}}>
                                    <View style={{flex:1,flexGrow:1,paddingRight:"5px"}}>
                                        <Label
                                            bsStyle="info"
                                            style={buttonSize==="small"?{lineHeight:2,fontSize:"12px"}:{lineHeight:2,fontSize:"18px"}}
                                        >Stream Resolution</Label>
                                    </View>
                                </View>
                                <View style={{paddingTop:"5px",flex:1,flexDirection:"row"}}>
                                    <ButtonGroup justified style={{width:"100%"}}>
                                        <LoaderButton
                                            bsStyle="default"
                                            bsSize={buttonSize}
                                            style={{width:"50%"}}
                                            active={streamQuality==="low"}
                                            disabled={streamQuality==="low"}
                                            isLoading={changeStreamLoading&&streamQuality==="low"}
                                            onClick={() => setStreamQuality("low")}
                                        >Standard</LoaderButton>
                                        <LoaderButton
                                            bsStyle="default"
                                            bsSize={buttonSize}
                                            style={{width:"50%"}}
                                            active={streamQuality==="high"}
                                            disabled={streamQuality==="high"}
                                            isLoading={changeStreamLoading&&streamQuality==="high"}
                                            onClick={() => setStreamQuality("high")}
                                        >High</LoaderButton>
                                    </ButtonGroup>
                                    <View style={{paddingLeft:"5px"}}>
                                        <Button
                                            bsStyle="default"
                                            bsSize={buttonSize}
                                            onClick={() => setShowResolutionHelp(!showResolutionHelp)}
                                        ><Glyphicon glyph="info-sign" /></Button>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{padding:"5px"}}>
                        <Collapse in={showResolutionHelp}>
                            <Well>
                                <b>High Resolution:</b> If your site has a fast cellular connection, you can use this setting to pull high-resolution images. It will automatically pull between 2MP to 4MP images depending on cellular speed. At peak speed, it will pull one image per second. If you want an even faster video, Standard Resolution is a good option.<br /><br />
                                <b>Standard Resolution:</b> We recommend using this resolution if the construction site's cellular connection is slow. Under normal conditions, this setting can pull between 5-24 images per second to create a smooth video.
                            </Well>
                        </Collapse>
                    </View>
                    <View style={{paddingLeft:"10px"}}>
                        <span style={{fontSize:buttonSize==="small"?"12px":"16px"}}>
                            {streaming && signalWeak ? "Connection is weak, stream may lag behind real time..." : 
                            streaming ? "" : 
                            fullyLoaded ? "Stream timed out, click refresh to reload the live view" :
                            ""
                            }
                        </span>
                    </View>
                    {(url || (savedUrl && savedUrl.url)) ? (
                        <TransformComponent className="test" wrapperStyle={{width:"100%",aspectRatio:1.7684}} contentStyle={{width:"100%",aspectRatio:1.7684}}>
                            {/* wrapperStyle={{width:"100%",height:"100%"}} contentStyle={{width:"100%",height:"100%"}} */}
                            {componentMounted.current && (
                                <>
                                    <ReactPlayer
                                        ref={playerRef}
                                        url={url}
                                        // controls
                                        width='100%'
                                        height='auto'
                                        style={{padding: 5,position:'absolute',aspectRatio:1.7684,display:"block",zIndex:fullyLoaded ? streamQuality==="low"? 10 : 0 : 0}}
                                        playsinline
                                        playing={playing}
                                        // onReady={() => setFullyLoaded(true)}
                                        onStart={() => setFullyLoaded(true)}
                                        // light={savedUrl.url}
                                    />
                                    <ReactPlayer
                                        ref={playerRefHigh}
                                        url={fullyLoaded ? urlHigh : ""}
                                        width='100%'
                                        height='auto'
                                        style={{padding: 5,position:'absolute',aspectRatio:1.7684,display:'block',zIndex:fullyLoaded ? streamQuality==="high" ? 10 : 0 : 0}}
                                        playsinline
                                        playing={playing}
                                        onStart={() => {
                                            setFullyLoaded(true);
                                            setStreamQuality("high");
                                            setUrl("");
                                        }}
                                    />
                                    <img
                                        style={{padding: 5, width: "100%", height: "100%", cursor: "pointer", zIndex:fullyLoaded ? 0 : 10 }} 
                                        src={savedUrl.url}
                                    />
                                </>
                            )}
                        </TransformComponent>
                    ) : <TransformComponent /> }
                    {/* <View style={{paddingTop:"5px",flex:1,flexDirection:"row",alignContent:"center",justifyContent:"center"}}>
                        <Button
                            bsSize={buttonSize}
                            bsStyle="default"
                            onClick={() => setMjpegTime(mjpegTime => mjpegTime-1)}
                        >DEBUG</Button>
                        <View style={{paddingLeft:"5px"}}>
                        <Button
                            bsSize={buttonSize}
                            bsStyle="warning"
                            onClick={() => props.history.push(`/caps/${cap.capId}/cameras/${props.match.params.monitorid}`)}
                        >Previous Version</Button>
                        </View>
                    </View> */}
                </View>
            )}
            </TransformWrapper>
        )
    }

    function renderMissing() {
        return (
            <div style={{paddingTop: "100px", textAlign: "center"}}>
                <h3>Stream cannot be found, please refresh or go back to all cameras</h3>
            </div>
        )
    }
    
    return (
        <div className="Cameras">
            {renderError ? renderMissing() : (!isLoading && renderLargeCamera())}
        </div>
    );
}