import React, {useState, useEffect} from "react";
import {
    Modal,
    FormGroup,
    FormControl,
    ControlLabel,
    Glyphicon,
    Button,
    Well
} from "react-bootstrap";
import { View } from "react-native";
import { addDaysToDate, addHoursToDate, addMinutesToDate, filterMonitorsByMonitorId, getMonitorsGroupById, getUser } from "../utils/Common";
import { getMonitors, getLatestStoredImages, makeTransferRequest } from "../utils/bedrockAPI";
import { TimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import styled from 'styled-components';
import "./FootageModal.css";
import LoaderButton from "./LoaderButton";

const CustomTimePicker = styled(TimePicker)`
    & .MuiInputBase-input {
        font-size: 24px;
        border: 1px solid #CCC;
        padding: 5px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px;
    }
    
    & .MuiInputBase-input:hover {
        background-color: #F5F5F5
    }
`;

const CustomDatePicker = styled(DatePicker)`
    & .MuiInputBase-input {
        font-size: 24px;
        border: 1px solid #CCC;
        padding: 5px;
        cursor: pointer;
        text-align: center;
        border-radius: 6px;
    }
    
    & .MuiInputBase-input:hover {
        background-color: #F5F5F5
    }
`;

export default function FootageModal({
    show,
    setShowFootage,
    footageMonitor,
    roughStartDate,
    cap,
    props
}) {
    const [buttonSize, setButtonSize] = useState("large");
    const [footageDate, setFootageDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [project, setProject] = useState("");
    const [errors, setErrors] = useState(null);
    const [cams, setCams] = useState(null);
    const [urls, setUrls] = useState(null);
    const [selectedMonitor,setSelectedMonitor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [requestErrors, setRequestErrors] = useState(null);
    const [transfer, setTransfer] = useState(null);

    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
    },[]);

    useEffect(() => {
        if(roughStartDate) {
            let local = new Date(roughStartDate);
            if(typeof roughStartDate === 'string') {
                local = new Date(roughStartDate.substring(0,roughStartDate.length - 1));
            }
            setFootageDate(local);
            setStartTime(local);
            setEndTime(addMinutesToDate(local,5));
        }
    },[roughStartDate]);

    useEffect(() => {
        if(footageMonitor && cams) {
            const g = getMonitorsGroupById(cams,footageMonitor);
            setSelectedMonitor(g.high.monitorId);
        }
    },[footageMonitor,cams])

    useEffect(() => {
        if(startTime && endTime) {
            if (startTime > endTime) {
                setErrors("Make sure the end time is after the start time");
            } else if (addHoursToDate(startTime,2) < endTime) {
                setErrors("You can only request 2 hours of footage at a time");
            } else if(cap && !['ONLINE'].includes(cap.health)) {
                setErrors("System is currently not online, request cannot be placed")
            } else {
                setErrors(null);
            }
        }
    },[startTime, endTime]);

    useEffect(() => {
        if(errors) {
            console.log(startTime,endTime);
        }
    },[errors]);

    useEffect(() => {
        async function getStoredImages(capId) {
            const tempCams = await getMonitors(capId);
            setCams(tempCams);
            const tempSavedUrls = await getLatestStoredImages(capId);
            setUrls(tempSavedUrls);
        }
        if(cap && cap.capId) {
            getStoredImages(cap.capId);
        }
    },[cap]);

    function calculateMonitorZmId() {
        const m = filterMonitorsByMonitorId(cams,selectedMonitor);
        if(cap.architecture.includes("2CAM")) {
            if(m.name==="House_Ultra_Res") {
                return "11";
            } else if(m.name==="Neighbor_Ultra_Res") {
                return "12";
            }
        } else if(cap.architecture.includes("4CAM")) {
            if(m.name==="House_Ultra_Res") {
                return "5";
            } else if(m.name==="Neighbor_Ultra_Res") {
                return "6";
            } else if(m.name==="Street_Ultra_Res") {
                return "7";
            } else if(m.name==="Driveway_Ultra_Res") {
                return "8";
            }
        }
        console.log("monitor selection does not make sense?",m)
        return null;
    }

    function calculateDate(start=true) {
        let pickTime = endTime;
        if(start) {
            pickTime = startTime;
        }
        // Move into local time
        pickTime = new Date(pickTime.getTime() - pickTime.getTimezoneOffset()*60000);
        const year = footageDate.getFullYear();
        const month = footageDate.getMonth();
        const day = footageDate.getDate();
        const hours = pickTime.getHours();
        const minutes = pickTime.getMinutes();
        const seconds = 0;
        return (new Date(year,month,day,hours,minutes,seconds)).toISOString().substring(0,19).replace('T',' ');
    }

    async function makeRequest() {
        setIsLoading(true);
        setRequestErrors(null);
        if(!cap || !cap.capId || !getUser().userId || !selectedMonitor) {
            setRequestErrors("Please make sure everything above is correct");
        } else {
            setRequestErrors(null);
            const request = {
                capId: cap.capId,
                userId: getUser().userId,
                project: project,
                monitorZmId: calculateMonitorZmId(),
                startDate: calculateDate(true),
                endDate: calculateDate(false),
            }
            if(!request.monitorZmId) {
                setRequestErrors("Unable to find a view that makes sense");
            } else {
                try {
                    console.log(request);
                    const tempTransfer = await makeTransferRequest(request);
                    console.log(tempTransfer);
                    setTransfer(tempTransfer);
                } catch(e) {
                    console.log(e);
                    setRequestErrors(`Request failed, please try again`);
                }
            }
        }
        setIsLoading(false);
    }

    async function closeModal() {
        setTransfer(null);
        setShowFootage(false);
    }

    function calculateDisabled() {
        if(cap && cap.health==="ONLINE" && selectedMonitor && project && errors==null) {
            return false;
        }
        return true;
    }

    function renderImg(camera) {
        const mId = camera.medium.monitorId;

        const calculateClass = () => {
            if(selectedMonitor===camera.high.monitorId) {
                if(buttonSize==="small") {
                    return "selected-mobile";
                } else {
                    return "selected";
                }
            } else {
                if(buttonSize==="small") {
                    return "unselected-mobile";
                } else {
                    return "unselected";
                }
            }
        }
        
        if(urls && mId && urls[mId] && camera.medium.width>0) {
            return (
                <div className={calculateClass()} onClick={() => setSelectedMonitor(camera.high.monitorId)}>
                    <img 
                        style={{maxWidth:buttonSize==="small"?297:462}}
                        src={urls[mId].url}
                        alt="Loading Views..."
                        className="selected-image"
                    />
                </div>
            );
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => closeModal()}
            container={this}
            aria-labelledby="contained-modal-title"
            style={{paddingLeft:"0px"}}
            dialogClassName={buttonSize==="small"?"modal-dialog-phone":"modal-dialog"}
            backdrop={true}
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <View style={{flex:1,flexDirection:"row",justifyContent:"center",fontSize:"24px"}}>
                    <div>
                        <b>Footage Request</b>
                    </div>
                </View>
            </Modal.Header>
            <Modal.Body>
                {cap && (<Well>
                    <div>Please fill out the information below to request footage from <i>{cap.name}</i></div><br/ >
                    <ul>
                        <li>Requests may slow down live access to your system.</li>
                        <li>We will email you with a link to the footage when this completes.</li>
                        <li>Requests should take no more than 24 hours if the system is online.</li>
                        <li>Requests usually take less than 1 hour depending on how much footage you request.</li>
                        <li>The footage may include several minutes before your start time up until several minutes after your end time.</li>
                    </ul>
                </Well>)}
                {transfer ? (
                    <View style={{flex:1,flexDirection:"column"}}>
                        <div style={{fontSize:"24px",textAlign:"center",paddingBottom:"40px"}}>
                            Wait time is expected to be {Math.floor(transfer.waitTime/60.)+10} minutes.<br /><br />
                            You will receive an email with a link to the footage once it's complete.
                        </div>
                        <Button
                            bsStyle="info"
                            bsSize={buttonSize}
                            onClick={() => closeModal()}
                        >OK</Button>
                    </View>
                ) : (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <View style={{flex:1,flexDirection:"column"}}>
                            <FormGroup>
                                <View style={{flex:1,flexDirection:"row"}}>
                                    <ControlLabel style={{width:"50%"}}>
                                        Name this Request
                                    </ControlLabel>
                                    <FormControl
                                        autoFocus
                                        value={project}
                                        onChange={e => setProject(e.target.value)}
                                        style={{maxWidth:"303px",height:"40.5px"}}
                                    />
                                </View>
                            </FormGroup>
                            <FormGroup>
                                <View style={{flex:1,flexDirection:"row"}}>
                                    <ControlLabel style={{width:"50%",paddingRight:"10px"}}>
                                        Date
                                    </ControlLabel>
                                    <CustomDatePicker
                                        format="MMM d"
                                        value={footageDate}
                                        onChange={setFootageDate}
                                        minDate={addDaysToDate(new Date(),-14)}
                                        InputProps={{disableUnderline:true}}
                                        slot={{dialog:{disableEnforceFocus:true}}}
                                    />
                                </View>
                            </FormGroup>
                            <FormGroup>
                                <View style={{flex:1,flexDirection:"row"}}>
                                    <ControlLabel style={{width:"50%"}}>
                                        Start Time
                                    </ControlLabel>
                                    <CustomTimePicker
                                        // format="H:mm"
                                        value={startTime}
                                        onChange={setStartTime}
                                        InputProps={{disableUnderline:true}}
                                    />
                                </View>
                            </FormGroup>
                            <FormGroup>
                                <View style={{flex:1,flexDirection:"row"}}>
                                    <ControlLabel style={{width:"50%"}}>
                                        End Time
                                    </ControlLabel>
                                    <CustomTimePicker
                                        // format=":mm"
                                        value={endTime}
                                        onChange={setEndTime}
                                        InputProps={{disableUnderline:true}}
                                    />
                                </View>
                            </FormGroup>
                            <div style={{color:"red",fontSize:"24px",paddingBottom:"5px",textAlign:"center"}}>
                                {errors}
                            </div>
                            {cams && (
                                <>
                                <View style={{flex:1,flexDirection:"row",justifyContent:"center",textAlign:"center"}}>
                                    <ControlLabel>
                                        Select the camera view from which you need footage
                                    </ControlLabel>
                                </View>
                                <View style={{flex:1, flexDirection:"column",justifyContent:"center"}}>
                                    <View style={{flex:1, flexDirection:"row",justifyContent:"center"}}>
                                        {renderImg(cams.house)}
                                        {renderImg(cams.neighbor)}
                                    </View>
                                    {cap && !cap.architecture.includes("2CAM") && (
                                        <View style={{flex:1,flexDirection:"row",justifyContent:"center"}}>
                                            {renderImg(cams.street)}
                                            {renderImg(cams.driveway)}
                                        </View>
                                    )}
                                </View>
                                </>
                            )}
                            <div style={{color:"red",fontSize:"24px",paddingBottom:"5px",textAlign:"center"}}>
                                {requestErrors}
                                {requestErrors && (
                                    <div style={{fontSize:"16px"}}>
                                        If this problem persisists, please contact Bedrock Wireless: <a href="mailto:help@bedrockwireless.com">help@bedrockwireless.com</a>
                                    </div>
                                )}
                            </div>
                            <View style={{flex:1,flexDirection:"row",paddingTop:"10px"}}>
                                <View style={{width:"50%",paddingLeft:"10px",paddingRight:"10px"}}>
                                    <Button
                                        bsStyle="default"
                                        bsSize={buttonSize}
                                        onClick={() => closeModal()}
                                        block
                                    >
                                        Cancel
                                    </Button>
                                </View>
                                <View style={{width:"50%",paddingLeft:"10px",paddingRight:"10px"}}>
                                    <LoaderButton
                                        bsStyle="info"
                                        bsSize={buttonSize}
                                        onClick={makeRequest}
                                        isLoading={isLoading}
                                        disabled={calculateDisabled()}
                                        block
                                    >
                                        Make Request
                                    </LoaderButton>
                                </View>
                            </View>
                        </View>
                    </MuiPickersUtilsProvider>
                )}
            </Modal.Body>
        </Modal>
    )
}